/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    skype: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M4.671 0c.88 0 1.733.247 2.468.702a7.42 7.42 0 016.02 2.118 7.37 7.37 0 012.167 5.215q0 .517-.072 1.026a4.66 4.66 0 01.6 2.281 4.64 4.64 0 01-1.37 3.294A4.67 4.67 0 0111.18 16c-.84 0-1.658-.226-2.37-.644a7.42 7.42 0 01-6.114-2.107A7.37 7.37 0 01.529 8.035q0-.545.08-1.081a4.644 4.644 0 01.76-5.59A4.68 4.68 0 014.67 0zm.447 7.01c.18.309.43.572.729.769a7 7 0 001.257.653q.737.308 1.145.523c.229.112.437.264.615.448.135.142.21.331.21.528a.87.87 0 01-.335.723c-.291.196-.64.289-.99.264a2.6 2.6 0 01-1.048-.206 11 11 0 01-.532-.253 1.3 1.3 0 00-.587-.15.72.72 0 00-.501.176.63.63 0 00-.195.491.8.8 0 00.148.482 1.2 1.2 0 00.456.354 5.1 5.1 0 002.212.419 4.6 4.6 0 001.624-.265 2.3 2.3 0 001.08-.801c.267-.39.402-.855.386-1.327a2.1 2.1 0 00-.279-1.101 2.5 2.5 0 00-.772-.792A7 7 0 008.486 7.3a1 1 0 00-.145-.058 18 18 0 01-1.013-.447 1.8 1.8 0 01-.54-.387.73.73 0 01-.2-.508.8.8 0 01.385-.723 1.76 1.76 0 01.968-.247c.26-.003.52.03.772.096q.412.119.802.293c.105.049.22.075.336.076a.6.6 0 00.453-.19.7.7 0 00.18-.496.72.72 0 00-.17-.476 1.4 1.4 0 00-.556-.354 3.7 3.7 0 00-.708-.183 6 6 0 00-1.022-.078 4.5 4.5 0 00-1.536.258 2.7 2.7 0 00-1.174.784 1.9 1.9 0 00-.45 1.287c-.01.37.076.736.25 1.063"/>',
    },
});
